import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  const allTitleParts = title.split(" ")
  const lastTitlePart = allTitleParts.pop()
  const joined = allTitleParts.join(" ")
  header = (
    <div className="header-row">
      <h1 className="main-heading">
        <Link to="/">
          {joined}{" "}
          <span style={{ color: "rgb(65, 190, 145)" }}>{lastTitlePart}</span>
        </Link>
      </h1>
      <ul className="header-menu">
        <Link to="/">Blog</Link>
        <Link to="/">About</Link>
        <Link to="/">Login</Link>
      </ul>
    </div>
  )
  // else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <>
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <header className="global-header">{header}</header>
        <main>{children}</main>
      </div>
      <footer className="global-footer">
        © {new Date().getFullYear()}, Solar For Renters
        {` `}| <Link to="/contact">Contact Us</Link>|{" "}
        <Link href="/privacy">Privacy Policy</Link>
      </footer>
    </>
  )
}

export default Layout
